import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';
import 'lightgallery/scss/lg-thumbnail.scss';
import 'lightgallery/scss/lg-pager.scss';
import 'lightgallery/scss/lg-fullscreen.scss';
import 'lightgallery/scss/lg-video.scss';

import lightGallery from 'lightgallery';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgPager from 'lightgallery/plugins/pager';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgVideo from 'lightgallery/plugins/video';
import lgZoom from 'lightgallery/plugins/zoom';

import { fromUnixTime, format } from 'date-fns';
import { lightGalleryLicenseKey } from 'constants/lightgallery';

document.addEventListener('DOMContentLoaded', () => {
  const timeline = document.getElementById('timeline');
  const timelinePhotoItems = timeline.querySelectorAll('.photo-item');
  const photoCount = timelinePhotoItems.length + 1;

  timeline.style.counterReset = `photo-count ${photoCount}`;

  document.querySelectorAll('.date').forEach((el) => {
    const dateEl = el;
    const timestamp = el.dataset.timestamp;
    if (timestamp) {
      dateEl.textContent = format(fromUnixTime(Number(timestamp)), 'PP');
    }
  });

  let lgInstance = null;

  const initializeLightGallery = () => {

    const gridItems = document.querySelectorAll('.photo-grid .photo-item');

    const images = Array.from(gridItems).map((item) => {
      return item.dataset.videoId ? {
        thumb: item.dataset.thumb,
        poster: item.dataset.thumb,
        html5: true,
        video: {
          source: [
            {
              src: item.dataset.mp4Url,
              type: 'video/mp4',
            },
          ],
          attributes: {
            preload: 'auto',
            controls: true,
          },
        },
      }
        : {
          thumb: item.dataset.thumb,
          src: item.dataset.full,
        };
    });

    lgInstance = lightGallery(document.querySelector('.photo-grid'), {
      dynamic: true,
      dynamicEl: images,
      plugins: [lgFullscreen, lgPager, lgThumbnail, lgVideo, lgZoom],
      showZoomInOutIcons: true,
      actualSizeIcons: {
        zoomIn: 'lg-actual-size',
        zoomOut: 'lg-actual-size',
      },
      showThumbByDefault: false,
      autoplayVideoOnSlide: true,
      gotoNextSlideOnVideoEnd: false,
      toggleThumb: true,
      pager: false,
      allowMediaOverlap: true,
      licenseKey: lightGalleryLicenseKey,
    });

    gridItems.forEach((item, idx) => {
      item.addEventListener('click', (ev) => {
        ev.preventDefault();
        lgInstance.openGallery(idx);
      });
    });
  };

  initializeLightGallery();

  const sortButton = document.querySelector('.sort-button');
  if (sortButton) {
    sortButton.addEventListener('click', () => {
      timeline.classList.toggle('reverse');
      sortButton.querySelector('span').classList.toggle('active');

      if (lgInstance) {
        lgInstance.destroy();
        initializeLightGallery();
      }
    });
  }
});

// Let's lazyload the background images
function BackgroundNode({ node, loadedClassName }) {
  const src = node.getAttribute('data-background-image-url');
  const show = (onComplete) => {
    window.requestAnimationFrame(() => {
      // eslint-disable-next-line no-param-reassign
      node.style.backgroundImage = `url(${src})`;
      node.classList.add(loadedClassName);
      onComplete();
    });
  };

  return {
    node,

    // onComplete is called after the image is done loading.
    load: (onComplete) => {
      const img = new Image();
      img.onload = show(onComplete);
      img.src = src;
    },
  };
}

const defaultOptions = {
  selector: '[data-background-image-url]',
  loadedClassName: 'loaded',
};

function BackgroundLazyLoader({ selector, loadedClassName } = defaultOptions) {
  let nodes = [].slice
    .apply(document.querySelectorAll(selector))
    .map((node) => new BackgroundNode({ node, loadedClassName }));

  const callback = (entries, observer) => {
    entries.forEach(({ target, isIntersecting }) => {
      if (!isIntersecting) {
        return;
      }

      const obj = nodes.find((it) => it.node.isSameNode(target));

      if (obj) {
        obj.load(() => {
          // Unobserve the node:
          observer.unobserve(target);
          // Remove this node from our list:
          nodes = nodes.filter((n) => !n.node.isSameNode(target));

          // If there are no remaining unloaded nodes,
          // disconnect the observer since we don't need it anymore.
          if (!nodes.length) {
            observer.disconnect();
          }
        });
      }
    });
  };

  const observer = new window.IntersectionObserver(callback);
  nodes.forEach((node) => observer.observe(node.node));
}

BackgroundLazyLoader();
